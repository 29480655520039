import React, { useState } from 'react';
import MeetingCard from './MeetingCard';

// Define types for our meetings
interface PhysicalMeeting {
  type: 'Physical';
  name: string;
  time: string;
  location: string;
  directionlink: string;
}

interface OnlineMeeting {
  type: 'Online';
  name: string;
  time: string;
  zoomLink: string;
  meetingId: string;
  password: string;
}

type Meeting = PhysicalMeeting | OnlineMeeting;

const Meetings: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'All' | 'Physical' | 'Online'>('All');
  
  const meetings: Meeting[] = [
    // Physical Meetings
    { 
      type: 'Physical',
      name: 'Easy Does It Group', 
      time: 'Tuesday, 6:30 am - 7:30 pm',
      location: 'Muguga Green Primary School, Westlands',
      directionlink: 'https://maps.app.goo.gl/gegkhmX81bMTszH16',
    },
    { 
      type: 'Physical',
      name: 'Easy Does It Group', 
      time: 'Thursday, 6:30 pm - 7:30 pm',
      location: 'Muguga Green Primary School, Westlands',
      directionlink: 'https://maps.app.goo.gl/gegkhmX81bMTszH16',
    },
    { 
      type: 'Physical',
      name: 'JFT Group', 
      time: 'Monday, 5:00 pm - 6:00 pm',
      location: 'FPFK Church, Diani',
      directionlink: 'https://maps.app.goo.gl/XRn4fBpKN9k2qRrb6',
    },
    { 
      type: 'Physical',
      name: 'Hakika NA Group', 
      time: 'Tuesday, Thursday, Saturday, 5:00 pm - 6:00 pm',
      location: 'KAG Church, Next to GPO Mtwapa',
      directionlink: 'https://maps.app.goo.gl/21vnBy1NkeYwBxJ98'
    },
    {
      type: 'Physical',
      name: 'Tumaini NA Group', 
      time: 'Wednesday, 4:30 am - 5:30 am',
      location: 'Majengo Social Hall, Opposite Qubaa Mosque',
      directionlink: 'https://maps.app.goo.gl/21vnBy1NkeYwBxJ98',
      },
    // Online Meetings
    {
      type: 'Online',
      name: 'E-NA Kenya Swahili',
      time: 'Monday, 8:00 pm - :00 pm',
      zoomLink: 'https://zoom.us/j/130052399',
      meetingId: '130 052 399',
      password: '2246'
    },
    {
      type: 'Online',
      name: 'E-NA Kenya English',
      time: 'Tuesday, 6:30 pm - 7:30 pm',
      zoomLink: 'https://us04web.zoom.us/j/478374798',
      meetingId: '478 374 798',
      password: '2246'
    },
    {
      type: 'Online',
      name: 'E-NA Kenya English',
      time: 'Thursday, 6:30 pm - 7:30 pm',
      zoomLink: 'https://us04web.zoom.us/j/478374798',
      meetingId: '478 374 798',
      password: '2246'
    },
    {
      type: 'Online',
      name: 'E-NA Kenya Swahili',
      time: 'Friday, 8:00 pm - 9:00 pm',
      zoomLink: 'https://zoom.us/j/987654321',
      meetingId: '130 052 399',
      password: '2246'
    },
    {
      type: 'Online',
      name: 'E-NA Kenya English Speaker Meeting',
      time: 'Tuesday, 6:30 pm - 7:30 pm',
      zoomLink: 'https://zoom.us/j/987654321',
      meetingId: '130 052 399',
      password: '2246'
    }
  ];

  const meetingTypes = ['All', 'Physical', 'Online'];

  const handleLocationClick = (meeting: Meeting) => {
    if (meeting.type === 'Physical') {
      window.open(meeting.directionlink, '_blank');
    } else {
      window.open(meeting.zoomLink, '_blank');
    }
  };

  const filteredMeetings = meetings.filter(meeting => 
    activeTab === 'All' ? true : meeting.type === activeTab
  );

  return (
    <div className="w-full px-4 py-8 sm:px-6 md:px-8 lg:px-16 xl:px-40 ">
      <h2 className="text-white text-2xl sm:text-3xl font-bold leading-tight tracking-[-0.015em] px-2 sm:px-4 pb-3 pt-16 sm:pt-28">
        Meetings
      </h2>
      
      {/* Meeting Type Tabs */}
      <div className="pb-3 overflow-x-auto">
        <div className="flex border-b border-[#3a3a55] px-2 sm:px-4 gap-4 sm:gap-8 min-w-max">
          {meetingTypes.map((type) => (
            <button 
              key={type}
              onClick={() => setActiveTab(type as 'All' | 'Physical' | 'Online')}
              className={`flex flex-col items-center justify-center border-b-[3px] pb-[13px] pt-4 min-w-[60px] ${
                type === activeTab 
                  ? 'border-b-white text-white' 
                  : 'border-b-transparent text-[#9b9bbb]'
              }`}
            >
              <p className={`text-sm font-bold leading-normal tracking-[0.015em] ${
                type === activeTab ? 'text-white' : 'text-[#9b9bbb]'
              }`}>
                {type}
              </p>
            </button>
          ))}
        </div>
      </div>

      {/* Meeting Cards */}
      <div className="space-y-2 px-2 sm:px-4">
        {filteredMeetings.map((meeting, index) => (
          <MeetingCard
            key={index}
            meeting={meeting}
            onLocationClick={() => handleLocationClick(meeting)}
          />
        ))}
      </div>

     
    </div>
  );
};

export default Meetings;