import React from 'react';
import { motion } from 'framer-motion';

const AboutUs: React.FC = () => {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="relative w-full min-h-screen bg-[#0D0F1C] py-16 md:py-24">
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-5">
        <div className="w-full h-full" style={{
          backgroundImage: `radial-gradient(circle at 2px 2px, #A1C181 1px, transparent 0)`,
          backgroundSize: '24px 24px'
        }}/>
      </div>

      {/* Content Container */}
      <div className="relative z-10 container mx-auto px-4 md:px-8 lg:px-40">
        <div className="max-w-[960px] mx-auto space-y-12">
          {/* Main About Section */}
          <motion.div 
            className="space-y-6"
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
          >
            <motion.h2 
              className="text-3xl md:text-4xl font-black text-white tracking-tight"
              {...fadeInUp}
            >
              About Us
            </motion.h2>
            
            <motion.div 
              className="bg-[#1A1F38]/50 backdrop-blur-sm rounded-2xl p-6 md:p-8 space-y-4"
              {...fadeInUp}
            >
              <p className="text-white/90 text-lg leading-relaxed">
                Narcotics Anonymous Kenya (NA Kenya) is part of a global, community-based organization that helps individuals recover from drug addiction. We are a diverse fellowship, welcoming members from all backgrounds through the journey of recovery.
              </p>
              <p className="text-white/90 text-lg leading-relaxed">
                Our program is based on complete abstinence from all drugs, and we follow the twelve-step recovery model, similar to that of Alcoholics Anonymous.
              </p>
              
              <div className="py-4">
                <blockquote className="border-l-4 border-[#A1C181] pl-4 italic text-xl text-white font-medium">
                  "Any addict can stop using drugs, lose the desire to use, and find a new way to live"
                </blockquote>
              </div>
            </motion.div>
          </motion.div>

          {/* Mission & Values */}
          <motion.div 
            className="grid md:grid-cols-2 gap-6"
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
          >
            <motion.div 
              className="bg-[#1A1F38]/50 backdrop-blur-sm rounded-2xl p-6 md:p-8"
              {...fadeInUp}
            >
              <h3 className="text-[#A1C181] text-xl font-bold mb-4">Our Mission</h3>
              <p className="text-white/90 leading-relaxed">
                To provide a safe space where addicts can find hope, support, and the tools they need to live drug-free. Through regular meetings, literature, and community support, we provide the resources to help individuals overcome addiction and regain control of their lives.
              </p>
            </motion.div>
            
            <motion.div 
              className="bg-[#1A1F38]/50 backdrop-blur-sm rounded-2xl p-6 md:p-8"
              {...fadeInUp}
              transition={{ delay: 0.2 }}
            >
              <h3 className="text-[#A1C181] text-xl font-bold mb-4">Our Promise</h3>
              <p className="text-white/90 leading-relaxed">
                Our fellowship offers the message of hope and the promise of freedom from addiction. We believe in the power of community support and the principle that recovery is possible for anyone who seeks it.
              </p>
            </motion.div>
          </motion.div>

          {/* History Timeline */}
          <motion.div 
            className="space-y-6"
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
          >
            <motion.h3 
              className="text-2xl md:text-3xl font-bold text-white"
              {...fadeInUp}
            >
              Our History
            </motion.h3>
            
            <motion.div 
              className="space-y-8"
              {...fadeInUp}
            >
              <div className="relative pl-8 border-l-2 border-[#A1C181]/30">
                <div className="absolute left-[-8px] top-0 w-4 h-4 bg-[#A1C181] rounded-full" />
                <h4 className="text-[#A1C181] font-bold mb-2">1998</h4>
                <p className="text-white/90">
                  First NA meeting held at YMCA in Nairobi, laying the foundation for what would become a growing network of NA groups in the region.
                </p>
              </div>
              
              <div className="relative pl-8 border-l-2 border-[#A1C181]/30">
                <div className="absolute left-[-8px] top-0 w-4 h-4 bg-[#A1C181] rounded-full" />
                <h4 className="text-[#A1C181] font-bold mb-2">2017</h4>
                <p className="text-white/90">
                  Formation of the <em>Easy Does It</em> group at Westlands Primary School, now holding meetings at Muguga Primary School.
                </p>
              </div>
              
              <div className="relative pl-8">
                <div className="absolute left-[-8px] top-0 w-4 h-4 bg-[#A1C181] rounded-full" />
                <h4 className="text-[#A1C181] font-bold mb-2">Today</h4>
                <p className="text-white/90">
                  NA Kenya continues to grow, spreading the message that recovery is possible and offering support to all who seek it.
                </p>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;