import React from 'react';

interface PhysicalMeeting {
  type: 'Physical';
  name: string;
  time: string;
  location: string;
  directionlink: string;
}

interface OnlineMeeting {
  type: 'Online';
  name: string;
  time: string;
  zoomLink: string;
  meetingId: string;
  password: string;
}

type Meeting = PhysicalMeeting | OnlineMeeting;

interface MeetingCardProps {
  meeting: Meeting;
  onLocationClick: () => void;
}

const MeetingCard: React.FC<MeetingCardProps> = ({ meeting, onLocationClick }) => {
  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 sm:gap-4 bg-[#111118] p-3 sm:px-4 min-h-[72px] justify-between">
      <div className="flex items-start sm:items-center gap-3 sm:gap-4 w-full">
        <div className="text-white flex items-center justify-center rounded-lg bg-[#27273a] shrink-0 size-10 sm:size-12">
          {meeting.type === 'Online' ? '🌐' : '📍'}
        </div>
        <div className="flex flex-col justify-center flex-grow">
          <p className="text-white text-sm sm:text-base font-medium leading-normal line-clamp-1">{meeting.name}</p>
          <p className="text-[#9b9bbb] text-xs sm:text-sm font-normal leading-normal line-clamp-2">{meeting.time}</p>
          {meeting.type === 'Physical' ? (
            <p 
              className="text-[#9b9bbb] text-xs sm:text-sm font-normal leading-normal line-clamp-2 hover:text-white hover:underline cursor-pointer mt-1"
              onClick={onLocationClick}
            >
              {meeting.location}
            </p>
          ) : (
            <div className="flex flex-col mt-1">
              <p 
                className="text-[#9b9bbb] text-xs sm:text-sm font-normal leading-normal hover:text-white hover:underline cursor-pointer"
                onClick={onLocationClick}
              >
                Join Zoom Meeting
              </p>
              <div className="flex flex-col sm:flex-row sm:gap-2 text-[#9b9bbb] text-xs sm:text-sm font-normal leading-normal">
                <span>ID: {meeting.meetingId}</span>
                <span className="hidden sm:block">|</span>
                <span>Password: {meeting.password}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="hidden sm:block shrink-0">
        <div className="text-white flex size-7 items-center justify-center">
          {meeting.type === 'Online' ? '💻' : '🚶'}
        </div>
      </div>
    </div>
  );
};

export default MeetingCard;