
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import AboutUs from './components/AboutUs';
import LiteratureSection from './components/DailyReflections';
import Header from './components/Header';
import HeroSection from './components/Hero';
import Meetings from './components/Meetings';
import Footer from './components/Footer';


const Home: React.FC = () => {
  return (
    <>
      <HeroSection />
      <Meetings />
    </>
  );
};

function App() {
  return (
    <Router>
      <div 
        className="relative flex size-full min-h-screen flex-col bg-[#111118] dark group/design-root overflow-x-hidden"
        style={{fontFamily: '"Plus Jakarta Sans", "Noto Sans", sans-serif'}}
      >
        <div className="layout-container flex h-full grow flex-col">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/literature" element={<LiteratureSection />} />
            <Route path="/meetings" element={<Meetings />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;