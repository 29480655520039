import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  FaPhone, 
  FaWhatsapp,
  FaBars,
  FaTimes,
  FaChevronRight,
} from 'react-icons/fa';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const HELPLINE_NUMBER = "+254799200466";
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCall = () => {
    window.location.href = `tel:${HELPLINE_NUMBER}`;
  };

  const handleWhatsApp = () => {
    const message = encodeURIComponent("Hello, I'm reaching out for help from NA Kenya.");
    window.location.href = `https://wa.me/${HELPLINE_NUMBER.replace('+', '')}?text=${message}`;
  };

  const navigationItems = [
    { title: 'Meetings', path: '/meetings' },
    { title: 'About Us', path: '/about' },
    { title: 'Literature', path: '/literature' }
  ];

  // Custom Logo component
  const NALogo = () => (
    <svg 
      viewBox="0 0 24 24" 
      className="w-full h-full transform transition-transform duration-300 group-hover:scale-110"
      fill="currentColor"
    >
      <path d="M12 2L2 20h20L12 2zm0 4l7 12H5l7-12z" />
    </svg>
  );

  return (
    <header 
      className={`fixed top-0 w-full z-50 transition-all duration-300 border-b border-white/[0.2] ${
        hasScrolled ? 'bg-[#0D0F1C]/95 backdrop-blur-sm shadow-lg' : 'bg-[#0D0F1C]'
      }`}
    >
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between px-4 md:px-6 py-4">
          {/* Logo Section */}
          <Link 
            to="/"
            className="flex items-center gap-3 group cursor-pointer"
            onClick={() => setIsMenuOpen(false)}
          >
            <div className="size-8 md:size-10 relative overflow-hidden text-[#A1C181] flex items-center justify-center">
            <img 
                src="https://nakenya.s3.eu-north-1.amazonaws.com/na-logo.png"
                alt="NA Kenya Logo"
                className="w-full h-full object-contain transform transition-transform duration-300 group-hover:scale-110"
              />
            </div>
            <h1 className="text-xl md:text-2xl font-bold text-white group-hover:text-[#A1C181] transition-colors">
              NA Kenya
            </h1>
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center gap-8">
            {navigationItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`text-white text-sm font-medium relative group overflow-hidden ${
                  location.pathname === item.path ? 'text-[#A1C181]' : ''
                }`}
              >
                <span className="relative z-10 transition-colors group-hover:text-[#A1C181]">
                  {item.title}
                </span>
                <span className={`absolute bottom-0 left-0 w-full h-0.5 bg-[#A1C181] transform 
                  ${location.pathname === item.path ? 'translate-x-0' : 'translate-x-[-100%]'}
                  group-hover:translate-x-0 transition-transform duration-300`} 
                />
              </Link>
            ))}
          </nav>

          {/* Desktop Contact Button */}
          <div className="hidden md:flex items-center gap-3">
            <div className="relative group">
              <div className="absolute inset-0 bg-[#A1C181] rounded-lg blur opacity-25 group-hover:opacity-40 transition-opacity" />
              <button
                onClick={handleCall}
                className="relative bg-[#1A1F38] border-2 border-[#A1C181] text-[#A1C181] hover:bg-[#A1C181] hover:text-[#1A1F38] px-4 py-2 rounded-lg font-medium text-sm transition-all duration-300 flex items-center gap-2"
              >
                <FaPhone className="size-4" />
                <span>{HELPLINE_NUMBER}</span>
              </button>
            </div>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden relative z-50 w-10 h-10 text-[#A1C181] focus:outline-none flex items-center justify-center"
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <FaTimes size={24} />
            ) : (
              <FaBars size={24} />
            )}
          </button>
        </div>

        {/* Mobile Menu Overlay */}
        <div 
          className={`fixed inset-0 bg-[#1A1F38]/95 backdrop-blur-lg transform transition-all duration-300 md:hidden ${
            isMenuOpen ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
          }`}
        >
          <div className="flex flex-col h-full pt-20 px-6">
            <nav className="flex flex-col gap-6">
              {navigationItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`text-white text-2xl font-medium hover:text-[#A1C181] transition-colors 
                    flex items-center justify-between group ${
                      location.pathname === item.path ? 'text-[#A1C181]' : ''
                    }`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  <span>{item.title}</span>
                  <FaChevronRight className="size-4 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all" />
                </Link>
              ))}
            </nav>
            
            {/* Mobile Contact Buttons */}
            <div className="mt-auto mb-8 space-y-4">
              <button
                onClick={handleCall}
                className="w-full bg-[#A1C181] text-[#1A1F38] py-4 rounded-lg font-bold text-lg flex items-center justify-center gap-2 hover:bg-[#91b171] transition-colors"
              >
                <FaPhone className="size-5" />
                Call Helpline
              </button>
              
              <button
                onClick={handleWhatsApp}
                className="w-full bg-green-500 text-white py-4 rounded-lg font-bold text-lg flex items-center justify-center gap-2 hover:bg-green-600 transition-colors"
              >
                <FaWhatsapp className="size-5" />
                WhatsApp Chat
              </button>

              <div className="text-center text-[#9b9bbb] text-sm mt-6">
                <p>24/7 Support Available</p>
                <p className="font-medium text-[#A1C181] mt-1">{HELPLINE_NUMBER}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;