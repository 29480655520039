import React, { useState } from 'react';
import { FaArrowRight, FaBook, FaChevronLeft, FaChevronRight, FaDownload, FaBars, FaFileAlt , FaLanguage} from 'react-icons/fa';
import PdfViewer from './PDFReader';

interface Literature {
  id: string;
  title: string;
  pdfUrl: string;
  description: string;
}

interface Pamphlet {
  id: string;
  title: string;
  pdfUrl: string;
  description: string;
}

const literatureList: Literature[] = [
  {
    id: 'It Works',
    title: 'It Works: How and Why',
    pdfUrl: 'https://nakenya.s3.eu-north-1.amazonaws.com/itworks.pdf',
    description: 'A detailed explanation of the Twelve Steps and Twelve traditions of Narcotics Anonymous'
  },
  {
    id: 'Living Clean',
    title: 'Living Clean',
    pdfUrl: 'https://nakenya.s3.eu-north-1.amazonaws.com/Living-Clean.pdf',
    description: 'Explore the spiritual principles of the NA Program and offer insights into Living Clean'
  },
  {
    id: 'NA Basic Text',
    title: 'NA Basic Text',
    pdfUrl: 'https://nakenya.s3.eu-north-1.amazonaws.com/NA%2BBasic%2BText%2B6th.pdf',
    description: 'The Core Literature of narcotics Anonymous, Outlining the program and the Twelve Steps'
  }
];

const pamphletsList = {
  swahili: [
    {
      id: 'IP-1-sw',
      title: 'Who, What, How and Why (IP-1)',
      pdfUrl: 'https://nakenya.s3.eu-north-1.amazonaws.com/sh3101-IP-1-Swahili.pdf',
      description: 'Basic information about NA in Swahili'
    },
    {
      id: 'IP-7-sw',
      title: 'Am I an Addict? (IP-7)',
      pdfUrl: 'https://nakenya.s3.eu-north-1.amazonaws.com/sh3107-IP-7-Swahili.pdf',
      description: 'Je, Mimi ni Mraibu?'
    },
    {
      id: 'IP-8-sw',
      title: 'Just for Today (IP-8)',
      pdfUrl: 'https://nakenya.s3.eu-north-1.amazonaws.com/sh3108-IP-8-Swahili.pdf',
      description: 'Kwa Leo Tu'
    },
    {
      id: 'IP-16-sw',
      title: 'For the Newcomer (IP-16)',
      pdfUrl: 'https://nakenya.s3.eu-north-1.amazonaws.com/sh3116-IP-16-Swahili.pdf',
      description: 'Kwa Mgeni'
    },
    {
      id: 'IP-19-sw',
      title: 'Self-Acceptance (IP-19)',
      pdfUrl: 'https://nakenya.s3.eu-north-1.amazonaws.com/sh3119-IP-19-Swahili.pdf',
      description: 'Kujikubali'
    },
    {
      id: 'IP-22-sw',
      title: 'Welcome to NA (IP-22)',
      pdfUrl: 'https://nakenya.s3.eu-north-1.amazonaws.com/sh3122-IP-22-Swahili.pdf',
      description: 'Karibu Kwenye NA'
    }
  ],
  english: [
    {
      id: 'IP-1-en',
      title: 'Who, What, How and Why',
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/EN3101-IP-1-English.pdf',
      description: 'Basic information about NA'
    },
    {
      id: 'IP-2-en',
      title: 'The Group',
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/EN3102-IP-2-English.pdf',
      description: 'Information about NA groups'
    },
    {
      id: 'IP-5-en',
      title: 'Another Look',
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/3105_Another-Look-IP-5-English.pdf',
      description: 'Information about NA groups'
    },
    {
      id: 'IP-6-en',
      title: 'Recovery and Relapse',
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/3106_Recovery-Relapse-IP-6-English.pdf',
      description: 'Understanding recovery and relapse'
    },
    {
      id: 'IP-7-en',
      title: 'Am I an Addict?',
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/EN3107-IP-7-English.pdf',
      description: 'Self-assessment guide'
    },
    {
      id: 'IP-8-en',
      title: 'Just for Today',
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/3108_Just-for-Today-IP-8-English.pdf',
      description: 'Living just for today'
    },
    {
      id: 'IP-9-en',
      title: 'Living the Program',
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/EN3109-IP-9-English.pdf',
      description: 'Guide to living the NA program'
    },
    {
      id: 'IP-11-en',
      title: 'Sponsorship Revised',
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/3111_Sponsorship-IP-11-English.pdf',
      description: 'Guide to sponsorship in NA'
    },
    {
      id: 'IP-12-en',
      title: 'The Triangle of Self-Obsession',
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/3112_Triangle-of-Self-Obsession-IP-12-English.pdf',
      description: 'Understanding self-obsession'
    },
    {
      id: 'IP-13-en',
      title: 'By Young Addicts, for Young Addicts',
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/EN3113_2008-IP-13-English.pdf',
      description: 'Recovery guide for young people'
    },
    {
      id: 'IP-14-en',
      title: `One Addict's Experience`,
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/3114_One-Addict-Experience-IP-14-English.pdf',
      description: 'Recovery guide for young people'
    },
    {
      id: 'IP-15-en',
      title: `PI and the NA Member`,
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/en3115-IP-15-English.pdf',
      description: 'Recovery guide for young people'
    },
    {
      id: 'IP-16-en',
      title: `For the Newcomer`,
      pdfUrl: 'https://www.na.org/admin/include/spaw2/uploads/pdf/litfiles/us_english/IP/EN3116.pdf',
      description: 'Recovery guide for young peopleFor The NewComer'
    },
    {
      id: 'IP-17-en',
      title: `For Those In Treatment`,
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/EN3117-IP-17-English.pdf',
      description: 'For Those in Treatment'
    },
    {
      id: 'IP-19-en',
      title: `For Those In Treatment`,
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/3119_Self-Acceptance-IP-19-English.pdf',
      description: 'Self Acceptance'
    },

    {
      id: 'IP-20-en',
      title: `H&I Service & the NA Member`,
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/EN3120-IP-20-English.pdf',
      description: 'H&I Service & the NA Member'
    },

    {
      id: 'IP-21-en',
      title: `The Loner`,
      pdfUrl: 'https://na.org/wp-content/uploads/2024/05/EN3121-IP-21-English.pdf',
      description: 'The Loner'
    },
  ]
};


const LiteratureSection: React.FC = () => {
  const [selectedContent, setSelectedContent] = useState<(Literature | Pamphlet) | null>(() => literatureList[0]);
  const [showPdfViewer, setShowPdfViewer] = useState(true); // Set to true by default
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 1024); // Only open by default on desktop
  const [activeSection, setActiveSection] = useState<'books' | 'pamphlets'>('books');
  const [activePamphletLanguage, setActivePamphletLanguage] = useState<'english' | 'swahili'>('english');


  const handleContentClick = (content: Literature | Pamphlet) => {
    setSelectedContent(content);
    setShowPdfViewer(true);
    if (window.innerWidth < 1024) {
      setIsSidebarOpen(false);
    }
  };

  const handleDownload = (pdfUrl: string, title: string) => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `${title.toLowerCase().replace(/\s+/g, '-')}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="min-h-screen pt-20 bg-[#0D0F1C]">
    <div className="container mx-auto px-4 lg:px-8 py-8">
      {/* Header section with improved mobile layout */}
      <div className="flex flex-col gap-6 mb-8 px-4">
        {/* Title and menu button */}
        <div className="flex justify-between items-center">
          <h2 className="text-2xl md:text-3xl font-bold text-white">Literature</h2>
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="lg:hidden text-[#A1C181] p-2 hover:bg-[#1b1b27] rounded-lg transition-colors"
            aria-label="Toggle sidebar"
          >
            <FaBars size={24} />
          </button>
        </div>

        {/* Mobile-optimized navigation */}
        <div className="flex flex-col gap-4 sm:flex-row sm:flex-wrap sm:items-center">
          {/* Section tabs - Full width on mobile */}
          <div className="grid grid-cols-2 gap-2 w-full sm:w-auto">
            <button
              onClick={() => setActiveSection('books')}
              className={`text-white px-4 py-3 rounded-lg transition-colors text-sm md:text-base ${
                activeSection === 'books' 
                  ? 'bg-[#A1C181] text-[#0D0F1C]' 
                  : 'bg-[#1b1b27] hover:bg-[#272936]'
              }`}
            >
              Books
            </button>
            <button
              onClick={() => setActiveSection('pamphlets')}
              className={`text-white px-4 py-3 rounded-lg transition-colors text-sm md:text-base ${
                activeSection === 'pamphlets' 
                  ? 'bg-[#A1C181] text-[#0D0F1C]' 
                  : 'bg-[#1b1b27] hover:bg-[#272936]'
              }`}
            >
              Pamphlets
            </button>
          </div>
          
          {/* Language selector - Full width on mobile when pamphlets active */}
          {activeSection === 'pamphlets' && (
            <div className="grid grid-cols-2 gap-2 w-full sm:w-auto sm:ml-auto">
              <button
                onClick={() => setActivePamphletLanguage('english')}
                className={`flex items-center justify-center gap-2 px-4 py-3 rounded-lg transition-colors text-sm md:text-base ${
                  activePamphletLanguage === 'english' 
                    ? 'bg-[#A1C181] text-[#0D0F1C]' 
                    : 'bg-[#1b1b27] text-white hover:bg-[#272936]'
                }`}
              >
                <FaLanguage className="text-lg" />
                English
              </button>
              <button
                onClick={() => setActivePamphletLanguage('swahili')}
                className={`flex items-center justify-center gap-2 px-4 py-3 rounded-lg transition-colors text-sm md:text-base ${
                  activePamphletLanguage === 'swahili' 
                    ? 'bg-[#A1C181] text-[#0D0F1C]' 
                    : 'bg-[#1b1b27] text-white hover:bg-[#272936]'
                }`}
              >
                <FaLanguage className="text-lg" />
                Swahili
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-8 relative">
        {/* Content List - Collapsible Sidebar */}
        <div className={`
          w-full lg:w-1/3 space-y-3 transition-all duration-300
            fixed lg:relative lg:inset-auto z-20 bg-[#0D0F1C] lg:bg-transparent
            ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
            top-20 bottom-0 left-0 right-0
            overflow-y-auto lg:overflow-visible
            px-4 lg:px-0 pt-4 lg:pt-0
        `}>
          {/* Mobile close button */}
          <div className="sticky top-0 right-0 z-30 w-full bg-[#0D0F1C] py-0 mb-0 lg:hidden flex justify-end">
          <button
            onClick={() => setIsSidebarOpen(false)}
            className="h-8 w-8 flex items-center justify-center text-[#A1C181]/50 hover:bg-[#1b1b27] rounded-lg transition-colors"
          >
            <FaChevronLeft size={16} />
          </button>
        </div>

          <div className="pb-20 lg:pb-0">
          {activeSection === 'books' ? (
            literatureList.map((book) => (
              <button
                key={book.id}
                onClick={() => handleContentClick(book)}
                className={`w-full flex items-center gap-4 p-4 rounded-lg transition-all duration-300
                  ${selectedContent?.id === book.id 
                    ? 'bg-[#A1C181] text-[#0D0F1C]' 
                    : 'bg-[#1b1b27] text-white hover:bg-[#272936]'
                  }
                `}
              >
                <div className="flex-shrink-0">
                  <FaBook className={`text-2xl ${
                    selectedContent?.id === book.id ? 'text-[#0D0F1C]' : 'text-[#A1C181]'
                  }`} />
                </div>
                <div className="flex-grow text-left">
                  <h3 className="font-medium text-base">{book.title}</h3>
                  <p className={`text-sm ${
                    selectedContent?.id === book.id ? 'text-[#0D0F1C]/80' : 'text-gray-400'
                  }`}>
                    {book.description}
                  </p>
                </div>
                <FaArrowRight className={`flex-shrink-0 transition-transform transform
                  ${selectedContent?.id === book.id ? 'translate-x-1 text-[#0D0F1C]' : 'text-[#A1C181]'}
                `} />
              </button>
            ))
          ) : (
            pamphletsList[activePamphletLanguage].map((pamphlet) => (
              <button
                key={pamphlet.id}
                onClick={() => handleContentClick(pamphlet)}
                className={`w-full flex items-center gap-4 p-4 rounded-lg transition-all duration-300
                  ${selectedContent?.id === pamphlet.id 
                    ? 'bg-[#A1C181] text-[#0D0F1C]' 
                    : 'bg-[#1b1b27] text-white hover:bg-[#272936]'
                  }
                `}
              >
                <div className="flex-shrink-0">
                  <FaFileAlt className={`text-2xl ${
                    selectedContent?.id === pamphlet.id ? 'text-[#0D0F1C]' : 'text-[#A1C181]'
                  }`} />
                </div>
                <div className="flex-grow text-left">
                  <h3 className="font-medium text-base">{pamphlet.title}</h3>
                  <p className={`text-sm ${
                    selectedContent?.id === pamphlet.id ? 'text-[#0D0F1C]/80' : 'text-gray-400'
                  }`}>
                    {pamphlet.description}
                  </p>
                </div>
                <FaArrowRight className={`flex-shrink-0 transition-transform transform
                  ${selectedContent?.id === pamphlet.id ? 'translate-x-1 text-[#0D0F1C]' : 'text-[#A1C181]'}
                `} />
              </button>
            ))
          )}
          </div>
        </div>

        {/* Overlay for mobile sidebar */}
        {isSidebarOpen && (
          <div 
            className="fixed inset-0 bg-black/50 z-10 lg:hidden"
            onClick={() => setIsSidebarOpen(false)}
          />
        )}

        {/* PDF Viewer Section */}
        <div className="w-full lg:w-2/3 bg-[#1b1b27] rounded-lg p-4">
          {selectedContent ? (
            <>
              <div className="bg-[#272936] rounded-t-lg p-4 mb-4 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                <div>
                  <h3 className="text-white text-lg sm:text-xl font-semibold mb-2">{selectedContent.title}</h3>
                  <p className="text-gray-400 text-sm">{selectedContent.description}</p>
                </div>
                <button
                  onClick={() => handleDownload(selectedContent.pdfUrl, selectedContent.title)}
                  className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-[#A1C181] text-[#0D0F1C] rounded-md hover:bg-[#91b171] transition-colors"
                >
                  <FaDownload size={16} />
                  <span>Download</span>
                </button>
              </div>
              <div className="bg-white rounded-lg">
                <PdfViewer fileUrl={selectedContent.pdfUrl} />
              </div>
            </>
          ) : (
            <div className="h-[600px] flex items-center justify-center text-gray-400 text-lg">
              <div className="text-center px-4">
                {activeSection === 'books' ? (
                  <FaBook size={48} className="text-[#A1C181] mx-auto mb-4" />
                ) : (
                  <FaFileAlt size={48} className="text-[#A1C181] mx-auto mb-4" />
                )}
                <p className="text-base sm:text-lg">
                  Select {activeSection === 'books' ? 'a book' : 'a pamphlet'} to start reading
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Navigation Controls */}
      <div className="fixed bottom-4 right-4 flex gap-2 lg:hidden">
        {showPdfViewer && (
          <button
            onClick={() => setIsSidebarOpen(true)}
            className="bg-[#A1C181] text-[#0D0F1C] p-4 rounded-full shadow-lg hover:bg-[#91b171] transition-colors"
            aria-label="Show menu"
          >
            <FaBars size={24} />
          </button>
        )}
      </div>
    </div>
  </div>
  );
};

export default LiteratureSection;