import React, { useState, useEffect, useMemo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { FaChevronLeft, FaChevronRight, FaMinus, FaPlus } from 'react-icons/fa';
import { MdScreenRotation } from 'react-icons/md';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Set up the worker with matching version
const pdfjsVersion = pdfjs.version;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`;

interface PDFViewerProps {
  fileUrl: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [scale, setScale] = useState(1);
  const [showRotateHint, setShowRotateHint] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = React.useRef<HTMLDivElement>(null);

  // Add rotation animation styles
  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.textContent = `
      @keyframes rotate {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(90deg); }
      }

      .animate-rotate {
        animation: rotate 1s ease-in-out infinite;
      }
    `;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  // Memoize options to prevent unnecessary reloads
  const options = useMemo(() => ({
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/cmaps/`,
    cMapPacked: true,
  }), []);

  // Calculate responsive scale based on container width
  const calculateScale = () => {
    if (!containerRef.current) return 1;
    const viewportWidth = containerRef.current.offsetWidth - 32; // subtract padding
    return Math.min(viewportWidth / 600, 1); // 600 is approximate standard page width
  };

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
        setScale(calculateScale());
      }
    };

    // Show rotation hint on mobile
    const showHint = () => {
      if (window.innerWidth < 768 && window.innerHeight > window.innerWidth) {
        setShowRotateHint(true);
        setTimeout(() => setShowRotateHint(false), 3000);
      }
    };

    updateDimensions();
    showHint();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  // Update scale when file changes
  useEffect(() => {
    setScale(calculateScale());
  }, [fileUrl]);

  useEffect(() => {
    // Reset state when fileUrl changes
    setNumPages(null);
    setPageNumber(1);
    setIsLoading(true);
    setError(null);
  }, [fileUrl]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  const onDocumentLoadError = (error: Error) => {
    console.error('PDF Load Error:', error);
    setError("Failed to load PDF. Please try again later.");
    setIsLoading(false);
  };

  const adjustScale = (delta: number) => {
    setScale(prevScale => {
      const baseScale = calculateScale();
      const newScale = Math.max(baseScale * 0.5, Math.min(2, prevScale + delta));
      return newScale;
    });
  };

  const LoadingSpinner = () => (
    <div className="absolute inset-0 flex items-center justify-center bg-white/80">
      <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#A1C181] border-t-transparent"></div>
    </div>
  );

  const ErrorDisplay = () => (
    <div className="absolute inset-0 flex items-center justify-center bg-white/80">
      <div className="text-red-500 text-center p-4">
        <p>{error}</p>
        <button 
          onClick={() => window.location.reload()}
          className="mt-2 px-4 py-2 bg-[#A1C181] text-[#0D0F1C] rounded-md hover:bg-[#91b171] transition-colors"
        >
          Retry
        </button>
      </div>
    </div>
  );

  const RotationHint = () => (
    <div 
      className={`
        fixed bottom-20 left-1/2 transform -translate-x-1/2 
        bg-[#272936] text-white px-4 py-3 rounded-lg shadow-lg z-50
        flex items-center gap-2 transition-opacity duration-300
        ${showRotateHint ? 'opacity-100' : 'opacity-0 pointer-events-none'}
      `}
    >
      <MdScreenRotation className="text-[#A1C181] text-xl animate-rotate" />
      <span className="text-sm">Rotate device for better reading</span>
    </div>
  );

  const renderControls = () => (
    <div className="flex flex-wrap items-center justify-between gap-4 mb-4 p-2 bg-[#272936] rounded-lg">
      <div className="flex items-center gap-2">
        <button
          onClick={() => setPageNumber(p => Math.max(1, p - 1))}
          disabled={pageNumber <= 1 || isLoading}
          className="flex items-center gap-2 px-3 py-2 bg-[#A1C181] text-[#0D0F1C] rounded-md 
            disabled:opacity-50 disabled:cursor-not-allowed hover:bg-[#91b171] transition-colors"
        >
          <FaChevronLeft className="w-4 h-4" />
          <span className="hidden sm:inline">Previous</span>
        </button>
        
        <div className="flex items-center gap-2">
          <input
            type="number"
            min={1}
            max={numPages || 1}
            value={pageNumber}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (!isNaN(value)) {
                setPageNumber(Math.min(Math.max(1, value), numPages || 1));
              }
            }}
            className="w-16 px-2 py-1 text-center bg-[#1b1b27] text-white rounded-md border border-[#3a3a55]"
          />
          <span className="text-white text-sm">of {numPages || '--'}</span>
        </div>
        
        <button
          onClick={() => setPageNumber(p => Math.min(numPages || p, p + 1))}
          disabled={pageNumber >= (numPages || 1) || isLoading}
          className="flex items-center gap-2 px-3 py-2 bg-[#A1C181] text-[#0D0F1C] rounded-md 
            disabled:opacity-50 disabled:cursor-not-allowed hover:bg-[#91b171] transition-colors"
        >
          <span className="hidden sm:inline">Next</span>
          <FaChevronRight className="w-4 h-4" />
        </button>
      </div>

      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <button
            onClick={() => adjustScale(-0.1)}
            className="text-[#A1C181] hover:text-[#91b171] p-2"
            aria-label="Zoom out"
          >
            <FaMinus size={16} />
          </button>
          <span className="text-white text-sm">
            {Math.round(scale * 100)}%
          </span>
          <button
            onClick={() => adjustScale(0.1)}
            className="text-[#A1C181] hover:text-[#91b171] p-2"
            aria-label="Zoom in"
          >
            <FaPlus size={16} />
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="pdf-viewer bg-[#1b1b27] rounded-lg p-4" ref={containerRef}>
      {renderControls()}

      <div className="bg-white rounded-lg overflow-auto min-h-[600px] relative">
        {isLoading && <LoadingSpinner />}
        {error && <ErrorDisplay />}

        <Document
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          loading={<LoadingSpinner />}
          error={<ErrorDisplay />}
          options={options}
        >
          <Page 
            pageNumber={pageNumber}
            className="mx-auto"
            renderAnnotationLayer={false}
            renderTextLayer={false}
            scale={scale}
            loading={<LoadingSpinner />}
          />
        </Document>
      </div>

      <RotationHint />
    </div>
  );
};

export default PDFViewer;