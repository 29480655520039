import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import bg from '../assets/q.jpg';

const HeroSection: React.FC = () => {
  const heroTexts = [
    "Recovery is possible. You're not alone.",
    "Break Free, Reclaim Your Life",
    "Freedom from Addiction Starts Here"
  ];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(false);
      setTimeout(() => {
        setCurrentTextIndex((prev) => (prev + 1) % heroTexts.length);
        setIsAnimating(true);
      }, 500);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full min-h-screen pt-16 md:pt-20">
      {/* Background Image with Overlay */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `linear-gradient(rgba(13, 15, 28, 0.7), rgba(13, 15, 28, 0.9)), url(${bg})`
        }}
      />

      {/* Content */}
      <div className="relative z-10 container mx-auto px-4 md:px-40 h-[calc(100vh-4rem)] md:h-auto md:py-24 flex items-center md:items-center">
        <div className="max-w-[960px] mx-auto w-full translate-y-16 md:translate-y-0">
          <div className="flex flex-col items-center justify-center md:min-h-[480px] text-center space-y-12">
            {/* Animated Hero Text */}
            <div className="min-h-[120px] md:min-h-[150px] flex items-center justify-center px-4">
              <h1 
                className={`text-3xl md:text-4xl lg:text-5xl font-black leading-tight tracking-[-0.033em] text-white transition-all duration-500 ease-in-out
                  ${isAnimating ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform -translate-y-4'}`}
              >
                {heroTexts[currentTextIndex]}
              </h1>
            </div>

            {/* Centered Button Section */}
            <div className="flex flex-col items-center gap-8">
              <button className="min-w-[200px] cursor-pointer overflow-hidden rounded-full py-4 px-8 bg-[#A1C181] text-[#0D0F1C] text-lg font-bold leading-normal tracking-[0.015em] hover:bg-[#91b171] transition-all duration-300 transform hover:scale-105 active:scale-95 shadow-lg hover:shadow-xl">
                Find Help
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;