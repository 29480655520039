import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
      <footer className="bg-[#0D0F1C] text-white py-12 border-t border-white/[0.2]">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">NA Kenya</h3>
              <p className="text-gray-400">
                Recovery is possible. You're not alone.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white">Find a Meeting</a></li>
                <li><Link to="/literature" className="text-gray-400 hover:text-white">Resources</Link></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Contact Us</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">24/7 Helpline</h3>
              <p className="text-gray-400">
                Call us anytime: +254799200466
              </p>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-8 text-center text-gray-400">
            <p>&copy; {new Date().getFullYear()} NA Kenya. All rights reserved.</p>
          </div>
        </div>
      </footer>
    );
  };

export default Footer;